// Libs
import React, { BaseSyntheticEvent } from 'react';
import _ from 'lodash';

// Components
import { InputNumber, Button, Switch, Input, Tooltip, Select } from 'antd';
import CoverModal from 'components/cover-modal';
import InsightFilter from 'components/insight/InsightFilter';

// Utils
import { modifyNestedSetItem } from 'utils/utils';

// Interfaces
import { IInsight, IFilter } from 'components/insight/Insight.interfaces';

// Icons
import { QuestionCircleOutlined } from '@ant-design/icons';

interface Props {
  insight: IInsight;
  id: string;
  type: string;
  onSave(insight: IInsight): void;
  onClose(): void;
};

interface State {
  insight: IInsight;
};

export const ALLOWED_SETTINGS = ['width', 'hidden', 'show_total', 'wrap_text', 'exclude_empty', 'exclude_zero', 'group_by', 'required', 'custom_title', 'tooltip', 'direction', 'aggregator', 'range', 'date_mode', 'default_values'];

class SettingsDialog extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    insight: this.props.insight,
  };

  getFiltersDefaultValues = (filters: IFilter[]): any => {
    let filterValues: any = {};

    filters.forEach((_filter: IFilter) => {
      filterValues[_filter.id] = _filter.settings.default_values;
    });

    return filterValues;
  };


  handleChange = (insight: IInsight, type: string, row: any, settings: any) => {
    this.setState({
      insight: {
        ...insight,
        [type]: modifyNestedSetItem(row.id, { ...row, settings: settings }, (insight as any)[type]),
      }
    });
  };

  renderSetting = (insight: IInsight, type: string, row: any) => {

    let component: { title?: string, tooltip: string | null, node: React.ReactNode } | null = null;
    let tooltip = null;

    switch (type) {
      case 'width':
        component = {
          tooltip: 'Sets the display width',
          node: (
            <InputNumber
              style={{ width: 250 }}
              value={ row['settings'][type] }
              addonAfter={ 'px' }
              onChange={ (value: number | string | null) => {
                this.handleChange(insight, this.props.type, row, { ...row.settings, [type]: value });
              } }
            />
          ),
        };
      break;
      case 'hidden':
        component = {
          tooltip: 'Makes this item invisible for the front end user',
          node: (
            <Switch
              checked={ row['settings'][type] }
              onChange={ (checked: boolean) => {
                this.handleChange(insight, this.props.type, row, { ...row.settings, [type]: checked });
              } }
            />
          ),
        };
        break;
      case 'exclude_empty':
        component = {
          tooltip: 'Select whether to exclude empty rows or show only empty rows',
          node: (
            <Select
              style={{ width: 250 }}
              value={ row['settings'][type] }
              placeholder="-"
              allowClear
              onChange={ (value: string | null) => {
                const updatedValue = value || '';
                this.handleChange(insight, this.props.type, row, { ...row.settings, [type]: updatedValue });
              }}
            >
              <Select.Option value="exclude_empty">Exclude Empty</Select.Option>
              <Select.Option value="include_empty_only">Include Empty Only</Select.Option>
            </Select>
          ),
        };
        break;
      case 'show_total':
      case 'wrap_text':
      case 'exclude_zero':
      case 'group_by':
      case 'required':
        component = {
          tooltip: tooltip,
          node: (
            <Switch
              checked={ row['settings'][type] }
              onChange={ (checked: boolean) => {
                this.handleChange(insight, this.props.type, row, { ...row.settings, [type]: checked });
              } }
            />
          ),
        };
      break;
      case 'custom_title':
      case 'tooltip':

        if (type === 'custom_title') {
          tooltip = 'Overrides the default title';
        }

        if (type === 'tooltip') {
          tooltip = 'Displays a tooltip with the provided text';
        }

        component = {
          tooltip: tooltip,
          node: (
            <Input
              style={{ width: 250 }}
              value={ row['settings'][type] }
              onChange={ (event: BaseSyntheticEvent | null) => {
                this.handleChange(insight, this.props.type, row, { ...row.settings, [type]: event?.target.value });
              } }
            />
          ),
        };
      break;
      case 'direction':
        component = {
          tooltip: 'Sets the sort to ascending or descending',
          node: (
            <Switch
              checkedChildren='Ascending'
              unCheckedChildren='Descending'
              checked={ row['settings'][type] === 'ASC' }
              onChange={ (checked: boolean) => {
                this.handleChange(insight, this.props.type, row, { ...row.settings, [type]: checked ? 'ASC' : 'DESC' });
              } }
            />
          ),
        };
      break;
      case 'aggregator':
        component = {
          tooltip: tooltip,
          node: (
            <Select
              allowClear
              placeholder={ '-' }
              style={{ width: 250 }}
              dropdownMatchSelectWidth={ false }
              value={ row['settings'][type] }
              onChange={ (aggregator: string) => {
                this.handleChange(insight, this.props.type, row, { ...row.settings, [type]: aggregator || null });
              } }
            >
              { ['SUM', 'AVG', 'MIN', 'MAX', 'COUNT', 'COUNT_UNIQUE'].map((aggregator: string) => (
                  <Select.Option key={ aggregator } value={ aggregator }>
                    { _.startCase(_.toLower(aggregator)).replaceAll('_', ' ') }
                  </Select.Option>
              ) ) }
            </Select>
          ),
        };
      break;
      case 'date_mode':
        component = {
          tooltip: tooltip,
          node: (
            <Select
              allowClear
              placeholder={ '-' }
              style={{ width: 250 }}
              dropdownMatchSelectWidth={ false }
              value={ row['settings'][type] }
              onChange={ (mode: string) => {
                // Clear defaults when change date mode
                this.handleChange(insight, this.props.type, row, { ...row.settings, [type]: mode || null, 'default_values': null });
              } }
            >
              { ['DATE', 'WEEK', 'MONTH', 'QUARTER', 'YEAR'].map((mode: string) => (
                  <Select.Option key={ mode } value={ mode }>
                    { _.startCase(_.toLower(mode)).replaceAll('_', ' ') }
                  </Select.Option>
              ) ) }
            </Select>
          ),
        };
      break;
      case 'range':
        component = {
          tooltip: tooltip,
          node: (
            <Switch
              checked={ row['settings'][type] }
              onChange={ (checked: boolean) => {
                // Clear defaults when change date mode
                this.handleChange(insight, this.props.type, row, { ...row.settings, [type]: checked, 'default_values': null });
              } }
            />
          ),
        };
      break;
      case 'default_values':
        component = {
          title: 'Default',
          tooltip: tooltip,
          node: (
            <InsightFilter
              filter={ row }
              filters={ this.getFiltersDefaultValues(insight.filters) }
              onSetFilters={ (filters: any, isRange?: boolean) => {
                const newSettings = !!isRange ? { ...row.settings, [type]: !!filters ? filters[row.id] :  null, range: isRange } : { ...row.settings };
                this.handleChange(insight, this.props.type, row, { ...newSettings, [type]: !!filters ? filters[row.id] :  null });
              } }
            />
          ),
        };
        break;
    }

    if (component) {
      return (
        <div className="d-f jc-sb mT-20">
          <span className="d-f ai-c">
            <span className="fw-600">{ component?.title || _.startCase(_.toLower(type)).replaceAll('_', ' ') }</span>
            { !!component.tooltip && (
              <span>
                <Tooltip className="mL-5" placement="top" title={ component.tooltip }>
                  <QuestionCircleOutlined className="cur-p fsz-def text-ant-default va-m" />
                </Tooltip>
              </span>
            ) }
          </span>
          <span className="d-f ai-c">{ component.node }</span>
        </div>
      );
    }

    return <></>;
  };

  render = () => {
    const { id, type, onSave, onClose } = this.props;
    const { insight } = this.state;
    const row = (insight as any)[type].find((item: any) => item.id === id);
    const settings = _.has(row, 'settings') && !_.isEmpty(row.settings) ? Object.keys(row.settings) : [];

    return (
      <CoverModal
        showCloseIcon={ false }
        style={{ width: 750, maxHeight: 700 }}
        middleContent={ `Settings - ${row.title} (${row.module_title})` }
        onClose={ onClose }
      >
        <div className="pX-20 pB-20 w-70p d-f fxd-c h-100p" style={{ margin: '0 auto' }}>
          <div className='d-f fxd-c fxg-1 jc-c'>
            { settings
              .filter((setting: string) => {
                return ALLOWED_SETTINGS.includes(setting);
              })
              .map((setting: string, index: number) => (
                <div key={ index }>
                  { this.renderSetting(insight, setting, row) }
                </div>
              ))
            }
          </div>
          <div>
            <div className='mB-20 mT-50 ta-r'>
              <Button
                onClick={ () => this.props.onClose() }
              >
                Cancel
              </Button>
              <Button
                type='primary'
                className="mL-5"
                onClick={ () => onSave(insight) }
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </CoverModal>
    );
  };
};

export default SettingsDialog;
